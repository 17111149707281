import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";

@Injectable()
export class LiveStreamService {
  private apiPrefix = '/client/live-streams';

  constructor(
    private api: ApiService,
  ) {
  }

  getAvailableStreams(qs = '') {
    return this.api.get(`${this.apiPrefix}${qs}`);
  }

  getStreamWithId(id: number) {
    return this.api.get(`${this.apiPrefix}/${id}`)
  }

  getStreamMessages(id: number, qs = '') {
    return this.api.get(`${this.apiPrefix}/${id}/messages${qs}`);
  }

  getActivePoll(id: number) {
    return this.api.get(`${this.apiPrefix}/${id}/active-poll`);
  }
}
